import React from 'react';
import PropTypes from 'prop-types';

import './styles/Video.css';

class Video extends React.Component {
  static propTypes = {
    videoSrcURL: PropTypes.string.isRequired,
    videoTitle: PropTypes.string.isRequired,
  };
  render() {
    const { videoSrcURL, videoTitle } = this.props;

    return (
      <div className="">
        <div className="Container Container--no-padding">
          <div className="Video">
            <iframe
              src={videoSrcURL}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              title={videoTitle}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Video;

import React from 'react';
import Modal from '../../../components/Modal';
import img from '../../../images/city/charleston_header.jpg';
import graphic from '../../../images/graphics/modal-charleston.jpg';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Charleston, SC',
  cityModal: true,
  type: 'image',
  image: {
    src: img,
    alt: 'Charleston, SC',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Charleston is the largest city in South Carolina. Historians estimate that
      nearly half of all Africans brought to America as slaves arrived here,
      making this city the only major antebellum one that had a
      majority-enslaved population. In 2015, a white supremacist entered a black
      church and murdered nine black parishioners. This resulted in the
      Confederate flag’s removal from the State Capitol and the ignition of a
      major nationwide debate about Confederate statues. In the last three
      decades, Charleston has become more diverse. Since 1980, the population
      that identifies as people of color grew from 33.4 percent to 36.8 percent.
      Since 2000, Charleston’s Latino population has had the largest growth. The
      median wage for workers of color is $6 less than the median wage for white
      workers. Impairing Charleston’s future is the 38 percent of jobs that
      require some college education. While more than 50 percent of whites meet
      this benchmark, only 25 percent of blacks and 44 percent of Latinos can
      compete. In Charleston, SC, we conducted focus groups with
      non-college-educated white, black and Latino residents. Learn more by
      reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>

    <img
      src={graphic}
      alt="Bar charts and map of racial distribution in Charleston, SC"
      className="Img-responsive"
    />
  </Modal>
);

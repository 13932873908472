import React from 'react';
import { Link } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import PropTypes from 'prop-types';
import Video from './Video';

import './styles/Modal.css';

class Modal extends React.Component {
  static propTypes = {
    closeTo: PropTypes.string.isRequired,
    media: PropTypes.shape({
      secTitle: PropTypes.string,
      type: PropTypes.oneOf(['audio', 'image', 'video']).isRequired,
      audio: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        src: PropTypes.string.isRequired,
      }),
      video: PropTypes.shape({
        src: PropTypes.string.isRequired,
        title: PropTypes.string,
      }),
      cityModal: PropTypes.bool,
    }),
  };

  assembleMedia() {
    const { media } = this.props;
    if ('undefined' === typeof media) {
      return;
    }

    const { type } = media;

    if ('image' === type) {
      const { alt, src } = media.image;
      return <img className="Modal__img" src={src} alt={alt} />;
    }

    if ('video' === type) {
      const { src, title } = media.video;
      return <Video videoSrcURL={src} videoTitle={title} />;
    }
  }

  getTitle() {
    const { media } = this.props;
    const { secTitle } = media;
    return secTitle;
  }

  learnedTitle() {
    const { media } = this.props;
    const { cityModal } = media;
    let learned = 'What We’ve Learned';
    if (cityModal === true) {
      return (
        <h3 className="Title--1-alt Title--c Section__title ">
          Where We’ve Been
        </h3>
      );
    }
    return <h3 className="Title--1-alt Title--c Section__title ">{learned}</h3>;
  }

  render() {
    const { children, closeTo } = this.props;

    return (
      <div className="Modal">
        <ModalRoutingContext>
          {({ modal }) => (
            <Link to={`/#${closeTo}`}>
              <span className="Modal__close" />
            </Link>
          )}
        </ModalRoutingContext>

        <div className="Modal__left">
          <div className="Modal__sticky-wrap Modal__sticky-left">
            {this.learnedTitle()}
            <br />
            <h4 className="Title--1 Title--a Title--line-1">
              <span className="Title__underline">{this.getTitle()}</span>
            </h4>
            {this.assembleMedia()}
          </div>
        </div>

        <div className="Modal__right">
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

export default Modal;
